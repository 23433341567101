// src/views/admin/default/components/AppointmentList.jsx

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  SimpleGrid,
  useColorModeValue,
  Spinner,
  Text,
  useBreakpointValue,
  Link,
  Select,
  FormControl,
  HStack,
  Divider,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import {
  FaCut,
  FaUserTie,
  FaCalendarAlt,
  FaPhone,
  FaSortUp,
  FaSortDown,
  FaTrash, // Import trash icon for delete button
} from "react-icons/fa";
import Card from "components/card/Card";
import {
  collection,
  query,
  where,
  orderBy as firestoreOrderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "firebaseConfig"; // Ensure this path is correct
import { formatDate } from "views/admin/default/components/utils"; // Adjust the path as needed
import CustomDatePicker from "views/admin/default/components/CustomDatePicker"; // Ensure this path is correct

import { useTranslation } from 'react-i18next'; // Import useTranslation

const AppointmentList = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]); // State to store users
  const [selectedUserId, setSelectedUserId] = useState(""); // State for selected user
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // Sorting state

  // Modal state for deletion
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);

  // Toast for notifications
  const toast = useToast();

  // Ref for the Cancel button in the modal
  const cancelButtonRef = useRef();

  // Chakra Color Mode Values - Defined at the top level
  const tableHeaderBg = useColorModeValue("gray.100", "gray.700");
  const tableRowBg = useColorModeValue("white", "gray.800");
  const tableRowHoverBg = useColorModeValue("gray.50", "gray.600");
  const collapseBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const tableBorderColor = useColorModeValue("gray.200", "gray.600"); // New variable

  // Determine table size based on screen width
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  // Fetch Users from Firestore
  useEffect(() => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, firestoreOrderBy("name", "asc")); // Assuming users have a 'name' field

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const usersData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));

        // Debug Log
        console.log("Fetched Users:", usersData);

        setUsers(usersData);
      },
      (error) => {
        console.error("Error fetching users:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Fetch Appointments from Firestore based on selected date and user
  useEffect(() => {
    setLoading(true);
    const formattedDate = format(selectedDate, "yyyy-MM-dd");

    // Debug Logs
    console.log("Selected Date:", selectedDate);
    console.log("Formatted Date:", formattedDate);
    console.log("Selected User ID:", selectedUserId);

    const appointmentsRef = collection(db, "appointments");
    let qRef;

    if (selectedUserId) {
      // If a user is selected, filter by userId
      qRef = query(
        appointmentsRef,
        where("date", "==", formattedDate),
        where("userId", "==", selectedUserId),
        firestoreOrderBy("time", "asc")
      );
    } else {
      // If no user is selected, fetch all appointments for the selected date
      qRef = query(
        appointmentsRef,
        where("date", "==", formattedDate),
        firestoreOrderBy("time", "asc")
      );
    }

    // Real-time listener
    const unsubscribe = onSnapshot(
      qRef,
      (querySnapshot) => {
        const appointmentsData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));

        // Debug Log
        console.log("Fetched Appointments:", appointmentsData);

        setAppointments(appointmentsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching appointments:", error);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [selectedDate, selectedUserId]);

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setSelectedDate(date);
    }
  };

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  // Handle sorting
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sort appointments based on sortConfig
  const sortedAppointments = React.useMemo(() => {
    let sortableAppointments = [...appointments];
    if (sortConfig.key !== "") {
      sortableAppointments.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle nested fields
        if (sortConfig.key === "customer") {
          aValue = a.personalDetails?.name || "";
          bValue = b.personalDetails?.name || "";
        } else if (sortConfig.key === "service") {
          aValue = a.serviceName || "";
          bValue = b.serviceName || "";
        }

        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === "string") {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAppointments;
  }, [appointments, sortConfig]);

  // Function to get sort icon
  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return null;
    }
    if (sortConfig.direction === "ascending") {
      return <Icon as={FaSortUp} ml={2} />;
    }
    return <Icon as={FaSortDown} ml={2} />;
  };

  // Handle delete button click
  const handleDeleteClick = (appointment) => {
    setAppointmentToDelete(appointment);
    onOpen();
  };

  // Confirm deletion
  const confirmDelete = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent any default behavior
      e.stopPropagation(); // Prevent event from bubbling up
    }

    if (!appointmentToDelete) return;

    try {
      await deleteDoc(doc(db, "appointments", appointmentToDelete.id));
      toast({
        title: t("appointment_deleted_successfully"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting appointment:", error);
      toast({
        title: t("error_deleting_appointment"),
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setAppointmentToDelete(null);
      onClose();
    }
  };

  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflowX={{ base: 'auto', lg: 'hidden' }} // Allow horizontal scroll on small screens
        >
          <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
            {/* You can add any header content here if needed */}
          </Flex>
          <Box overflowX="auto" width="100%" minH="500px">
            <Flex
              mb={4}
              justify="space-between"
              align="center"
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* Header and Filters */}
              <Text
                fontSize="2xl"
                fontWeight="bold"
                mb={{ base: 4, md: 0 }}
                color={textColor}
              >
                {/* You can add a heading here if needed */}
              </Text>
              <Flex
                align="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <HStack spacing={3}>
                  {/* Calendar Label and Picker */}
                  <FormControl id="select-date" mr={{ md: 2 }} mb={{ base: 2, md: 0 }}>
                    <CustomDatePicker selectedDate={selectedDate} onChange={handleDateChange} />
                  </FormControl>

                  {/* User Select Input */}
                  <FormControl id="select-user">
                    <Select
                      placeholder={t("select_user")}
                      value={selectedUserId}
                      onChange={handleUserChange}
                      maxWidth={{ base: "100%", md: "200px" }}
                    >
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>
              </Flex>
            </Flex>

            {loading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="xl" />
              </Flex>
            ) : sortedAppointments.length === 0 ? (
              <Flex justify="center" align="center" height="200px">
                <Text color={textColor}>{t("no_appointments_for_date")}</Text>
              </Flex>
            ) : (
              <Table
                variant="striped"
                colorScheme="gray"
                size={tableSize}
                width="100%"
                border="1px solid"
                borderColor={tableBorderColor} // Use the defined variable
                borderRadius="md"
                // Override default Chakra styles if necessary
                sx={{
                  "th, td": {
                    px: 4,
                    py: 2,
                    borderBottom: "1px solid",
                    borderColor: tableBorderColor,
                  },
                  "thead th": {
                    fontWeight: "bold",
                    textAlign: "left",
                    cursor: "pointer", // Indicate that headers are clickable
                  },
                }}
              >
                <Thead bg={tableHeaderBg}>
                  <Tr>
                    <Th onClick={() => handleSort("sn")}>
                      {t("sn")}
                      {/* Optional: Add sort icon if sorting by SN */}
                      {sortConfig.key === "sn" && (
                        sortConfig.direction === "ascending" ? <Icon as={FaSortUp} ml={2} /> : <Icon as={FaSortDown} ml={2} />
                      )}
                    </Th>
                    <Th onClick={() => handleSort("bookingCode")}>
                      {t("code")}
                      {getSortIcon("bookingCode")}
                    </Th>
                    <Th onClick={() => handleSort("time")}>
                      {t("time")}
                      {getSortIcon("time")}
                    </Th>
                    <Th onClick={() => handleSort("customer")}>
                      {t("customer")}
                      {getSortIcon("customer")}
                    </Th>
                    <Th onClick={() => handleSort("barberName")}>
                      {t("barber")}
                      {getSortIcon("barberName")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedAppointments.map((appt, index) => (
                    <React.Fragment key={appt.id}>
                      <Tr
                        bg={tableRowBg}
                        _hover={{ bg: tableRowHoverBg }}
                        cursor="pointer"
                        onClick={() => toggleRow(appt.id)}
                      >
                        <Td fontWeight="500">{index + 1}</Td> {/* SN Column */}
                        <Td fontWeight="500">{appt.bookingCode}</Td> {/* Set font-weight to 500 */}
                        <Td>{appt.time}</Td>
                        <Td>{appt.personalDetails?.name || t("n_a")}</Td>
                        <Td>{appt.barberName || t("n_a")}</Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={5} p={0} border="none">
                          <Collapse in={expandedRows[appt.id]} animateOpacity>
                            <Box
                              p={4}
                              bg={collapseBg}
                              borderBottomRadius="md"
                              boxShadow="sm"
                              mt={2}
                            >
                              <Flex
                                direction={{ base: "column", md: "row" }}
                                justify="space-between"
                                align={{ base: "flex-start", md: "center" }}
                              >
                                <Box mb={{ base: 2, md: 0 }} width="100%">
                                  <HStack spacing={3} minH="30px">
                                    <Link href={`tel:${appt.personalDetails?.phone}`}>
                                      <FaPhone color="teal" size={16} aria-label={t("phone_icon")} /> 
                                    </Link>
                                    <Text>
                                      {appt.personalDetails?.phone || t("n_a")}
                                    </Text>
                                  </HStack>
                                  <Divider my={2} />
                                  <HStack spacing={3} minH="30px">
                                    <FaCut color="teal" size={16} aria-label={t("service_icon")} />
                                    <Text>
                                      {t("service")}: {appt.serviceName || t("n_a")} {" -> "} {appt.price} CHF
                                    </Text>
                                  </HStack>
                                  <Divider my={2} />
                                  <HStack spacing={3} minH="30px">
                                    <FaCalendarAlt color="teal" size={16} aria-label={t("date_icon")} />
                                    <Text>
                                      {t("booked_at")}:{" "}
                                      {appt.createdAt && appt.createdAt !== "N/A"
                                        ? formatDate(appt.createdAt, "yyyy-MM-dd HH:mm")
                                        : t("n_a")}
                                    </Text>
                                  </HStack>
                                  <Divider my={2} />
                                  {/* Delete Button 
                                  <Button
                                    leftIcon={<FaTrash size="12px" />} // Reduced icon size
                                    colorScheme="red"
                                    variant="outline"
                                    size="sm" // Sets the button to Chakra UI's small size
                                    fontSize="0.8rem" // Decreases the font size
                                    padding="2px 6px" // Reduces the padding for a smaller footprint
                                    onMouseDown={(e) => { // Changed to onMouseDown
                                      e.preventDefault(); // Prevents default behavior
                                      e.stopPropagation(); // Prevents row toggle
                                      handleDeleteClick(appt);
                                    }}
                                  >
                                    {t("delete")}
                                  </Button>
                                  */}

                                </Box>
                              </Flex>
                            </Box>
                          </Collapse>
                        </Td>
                      </Tr>
                    </React.Fragment>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </Card>
      </SimpleGrid>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        initialFocusRef={cancelButtonRef} // Set initial focus to Cancel button
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("confirm_deletion")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {t("are_you_sure_you_want_to_delete_this_appointment")}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              ref={cancelButtonRef} // Assign ref to Cancel button
            >
              {t("cancel")}
            </Button>
            <Button colorScheme="red" onMouseDown={(e) => confirmDelete(e)}>
              {t("yes_delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AppointmentList;
