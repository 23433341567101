// src/website/components/booking/PersonalDetails.js
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../../../firebaseConfig";
import { useTranslation } from 'react-i18next';

const PersonalDetails = ({ personalDetails, setPersonalDetails, setIsPhoneVerified }) => {
  const { t } = useTranslation();
  
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const recaptchaVerifier = useRef(null);
  const recaptchaContainerRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    const initializeRecaptcha = () => {
      if (recaptchaContainerRef.current) {
        recaptchaContainerRef.current.innerHTML = '';
      }

      try {
        if (recaptchaVerifier.current) {
          try {
            recaptchaVerifier.current.clear();
          } catch (clearError) {
            console.warn("Error clearing previous reCAPTCHA:", clearError);
          }
        }

        recaptchaVerifier.current = new RecaptchaVerifier(
          auth,
          recaptchaContainerRef.current,
          {
            size: "invisible",
            callback: (response) => {
              console.log("reCAPTCHA solved", response);
            },
            "expired-callback": () => {
              toast({
                title: "reCAPTCHA Expired",
                description: "Please verify again.",
                status: "warning",
                duration: 3000,
                isClosable: true,
              });
            },
            "error-callback": (error) => {
              console.error("reCAPTCHA Error:", error);
              toast({
                title: "Verification Error",
                description: "Failed to verify. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
        );
        
      } catch (error) {
        console.error("Comprehensive reCAPTCHA Initialization Error:", error);
        toast({
          title: "Setup Failed",
          description: "Unable to set up verification. Please refresh.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    try {
      if (recaptchaContainerRef.current) {
        initializeRecaptcha();
      }
    } catch (error) {
      console.error("Unexpected error during reCAPTCHA setup:", error);
      toast({
        title: "Unexpected Error",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    return () => {
      try {
        if (recaptchaVerifier.current) {
          recaptchaVerifier.current.clear();
        }
      } catch (error) {
        console.warn("Error during reCAPTCHA cleanup:", error);
      }
    };
  }, [toast]);

  const sendOTP = async () => {
    if (!personalDetails.phone || !personalDetails.phone.startsWith('+')) {
      toast({
        title: "Invalid Phone Number",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      if (recaptchaVerifier.current) {
        await recaptchaVerifier.current.verify();
      }

      const result = await signInWithPhoneNumber(
        auth, 
        personalDetails.phone, 
        recaptchaVerifier.current
      );

      setConfirmationResult(result);
      toast({
        title: "OTP Sent",
        description: "Verification code has been sent to your phone.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("OTP Send Error:", error);
      
      let errorMessage = "Failed to send OTP. Please try again.";
      
      if (error.code === 'auth/invalid-phone-number') {
        errorMessage = "Invalid phone number. Please check and try again.";
      } else if (error.code === 'auth/quota-exceeded') {
        errorMessage = "SMS quota exceeded. Please try again later.";
      } else if (error.code === 'auth/internal-error') {
        errorMessage = "Internal verification error. Please check your configuration.";
      }

      toast({
        title: "OTP Send Failed",
        description: errorMessage,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    if (!otp || otp.length < 6) {
      toast({
        title: "Invalid OTP",
        description: "Please enter a valid verification code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      if (!confirmationResult) {
        throw new Error("No confirmation result. Please send OTP first.");
      }

      await confirmationResult.confirm(otp);
      
      toast({
        title: "Verification Successful",
        description: "Your phone number has been verified.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsPhoneVerified(true);
      setIsVerified(true);

    } catch (error) {
      console.error("OTP Verification Error:", error);
      
      let errorMessage = "Invalid verification code. Please try again.";
      
      if (error.code === 'auth/invalid-verification-code') {
        errorMessage = "The verification code is invalid. Please check and retry.";
      } else if (error.code === 'auth/code-expired') {
        errorMessage = "Verification code has expired. Please request a new one.";
      }

      toast({
        title: "Verification Failed",
        description: errorMessage,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Text fontSize="xl" mb={4}>
        {t("enter_personal_details")}
      </Text>
      <VStack spacing={6} align="stretch">
        <FormControl isRequired>
          <FormLabel>{t("name")}</FormLabel>
          <Input
            placeholder={t("your_name")}
            value={personalDetails.name}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                name: e.target.value,
              })
            }
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{t("phone_number")}</FormLabel>
          <PhoneInput
            country={"ch"}
            value={personalDetails.phone}
            onChange={(phone, country, e, formattedValue) => {
              // Reset verification status if the phone number changes after verification
              setIsPhoneVerified(false);
              setIsVerified(false);

              setPersonalDetails({
                ...personalDetails,
                phone: `+${phone}`,
              });
            }}
            inputStyle={{
              width: "100%",
              height: "40px",
              paddingLeft: "48px",
              borderRadius: "4px",
              borderColor: "#E2E8F0",
            }}
            buttonStyle={{
              borderColor: "#E2E8F0",
            }}
            containerStyle={{ width: "100%" }}
            disableSearch={false}
            placeholder={t("enter_phone_number")}
          />
        </FormControl>
      </VStack>

      <FormControl>
        <Box 
          ref={recaptchaContainerRef} 
          id="recaptcha-container"
          width="100%"
          display="none"
        />
      </FormControl>

      <Box mt={8} px={4}>
        <VStack spacing={6} align="center">
          {!confirmationResult && !isVerified && (
            <Button 
              colorScheme="blue" 
              onClick={sendOTP}
              isLoading={isLoading}
              width="100%"
              maxW="400px"
              height="45px"
              borderRadius="8px"
            >
              {t("send_otp")}
            </Button>
          )}
          {confirmationResult && !isVerified && (
            <VStack spacing={4} width="100%" maxW="400px">
              <FormControl isRequired>
                <FormLabel fontSize="sm">
                  {t("enter_verification_code")}
                </FormLabel>
                <Input
                  placeholder=""
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  maxLength={6}
                  textAlign="center"
                  fontSize="lg"
                  height="45px"
                  borderRadius="8px"
                />
              </FormControl>
              <Button 
                colorScheme="green" 
                onClick={verifyOTP}
                isLoading={isLoading}
                width="100%"
                height="45px"
                borderRadius="8px"
              >
                {t("verify_otp")}
              </Button>
            </VStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
